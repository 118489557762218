





















import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { sectionCoreProps } from '@/mixins/sectionCore'
import SectionBasic from '@/components/SectionBasic.vue'
import { CardTitleAndIcon as ICardTitleAndIcon } from '@/contracts/Cards'
import CardTitleAndIcon from '@/components/Cards/CardTitleAndIcon.vue'

export default defineComponent({
  name: 'SectionCardsTitleAndIcon',
  components: { CardTitleAndIcon, SectionBasic },
  mixins: [sectionCoreProps],
  props: {
    cols: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    cards: {
      type: Array as PropType<ICardTitleAndIcon[]>,
      default: () => [],
    },
  },
})
