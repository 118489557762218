





















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { CardTitleAndIcon } from '@/contracts/Cards'
import BlockButtons from '@/components/BlockButtons.vue'

export default defineComponent({
  name: 'CardTitleAndIcon',
  components: { BlockButtons },
  props: {
    card: {
      type: Object as PropType<CardTitleAndIcon>,
      required: true,
    },
  },
  setup(props) {
    const cardStyles = computed(() => ({
      '--card-background-color': props.card.backgroundColor,
      '--card-title-color': props.card.titleColor,
    }))

    return {
      cardStyles,
    }
  },
})
